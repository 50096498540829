<i18n>
  {
    "es": {
      "BRANDS": "Marcas"
    },
    "en": {
      "BRANDS": "Brands"
    }
  }
</i18n>

<template>
  <div>
    <div v-if="isMobileOrTablet">
      <MenuMobile
        :visibility="visibility"
        @close="emit('close')"
        :items="menu"
      />
    </div>
    <div class="max-w-1400 mx-auto lg:px-9 lg:mt-4">
      <div
        v-if="!isMobileOrTablet"
        class="flex items-center justify-center relative w-auto"
      >
        <button
          @click="scrollLeft"
          aria-label="scroll-left"
          v-if="scrolledRight"
          class="absolute top-1 -left-8 rotate-90"
        >
          <img src="@/assets/svg/icons/icons-arrow-down-outline.svg" />
        </button>

        <div class="overflow-hidden w-full">
          <ul
            class="mx-auto flex whitespace-nowrap overflow-x-auto scrollbar-hidden overflow-y-hidden appearance-none"
            :class="menu?.length > 11 ? 'w-full' : 'w-fit'"
            ref="desktopMenu"
            @mouseleave="hoverMenu(null, false)"
          >
            <li class="cursor-pointer w-fit relative block mr-5">
              <NuxtLink
                :to="`/${locale}/marcas`"
                class="rounded-2xl w-fit text-center after:w-0 hover:after:w-full hover:after:h-0.5 hover:after:bg-[#3E5391] hover:after:content-norpal hover:after:absolute hover:after:bottom-0 hover:after:left-0 after:transition-all after:duration-300 text-others-black tracking-[0.15em] text-xs font-bold uppercase whitespace-nowrap"
                >{{ t('BRANDS') }}</NuxtLink
              >
            </li>

            <li
              class="cursor-pointer w-fit mr-5 relative block"
              v-for="(section, index) in menu"
              :key="index"
              @mouseenter="hoverMenu(section, true)"
            >
              <NuxtLink
                :to="`/${locale}/${section.seotag}`"
                class="rounded-2xl w-fit text-center after:w-0 hover:after:w-full hover:after:h-0.5 hover:after:bg-[#3E5391] hover:after:content-normal hover:after:absolute hover:after:bottom-0 hover:after:left-0 after:transition-all after:duration-300 text-others-black tracking-[0.15em] text-xs font-bold uppercase whitespace-nowrap"
                >{{ section.name }}</NuxtLink
              >
            </li>
          </ul>
        </div>
        <button
          v-if="!scrolledRightEnd && menu?.length > 11"
          @click="scrollRight"
          aria-label="scroll-right"
          class="absolute top-1 -right-8 -rotate-90"
        >
          <img src="@/assets/svg/icons/icons-arrow-down-outline.svg" />
        </button>
      </div>
      <div
        v-if="isMobileOrTablet"
        class="flex basis-full h-57 justify-start xl:justify-center -mb-4 overflow-x-scroll overflow-y-hidden scrollbar-hidden mt-7 md:mt-5"
      >
        <NuxtLink
          :to="`/${locale}/marcas`"
          class="flex items-center font-bold tracking-[0.15em] cursor-pointer bg-others-grey rounded-2xl text-others-black flex-shrink-0 text-11 px-3 py-1.5 uppercase whitespace-nowrap ml-4 h-30"
          >{{ t('BRANDS') }}</NuxtLink
        >
        <NuxtLink
          v-for="(section, index) in menu"
          :key="index"
          :to="`/${locale}/${section.seotag}`"
          class="flex items-center font-bold tracking-[0.15em] cursor-pointer bg-others-grey rounded-2xl text-others-black flex-shrink-0 text-11 px-3 py-1.5 uppercase whitespace-nowrap ml-4 h-30"
          >{{ section.name }}</NuxtLink
        >
      </div>

      <div
        v-if="sectionHovered && !isMobileOrTablet"
        class="absolute left-0 w-full bg-white z-[1000] top-30 transition-all duration-300"
        @mouseenter="hoverCollapse"
        @mouseleave="hoverCollapse"
        :class="
          menuHovered ? 'h-[calc(100vh-45vh)] block' : ' max-h-0 h-0 hidden'
        "
      >
        <div
          class="w-full mx-auto max-w-1400 grid grid-cols-6 gap-x-4 py-4 px-2"
        >
          <template
            v-if="totalCategories > 6 && categoriesWithoutChildren.length > 0"
          >
            <div class="mt-2 col-span-5 grid grid-cols-5 gap-x-3 gap-y-2">
              <div
                v-for="category in categoriesWithChildren"
                :key="category.category_id"
                class="relative pt-[90%] w-[90%]"
              >
                <div class="absolute inset-0">
                  <MenuChipsSliderItem :category="category" />
                </div>
              </div>
            </div>

            <div class="mt-2 col-span-1 flex flex-col justify-start h-full">
              <div
                v-for="category in categoriesWithoutChildren"
                :key="category.category_id"
                class="mt-0.5 text-sm tracking-widest leading-none font-semibold relative uppercase cursor-pointer mb-3 after:w-0 hover:after:w-full hover:after:h-0.5 hover:after:bg-sanremo-primaryblue hover:after:content-normal hover:after:absolute hover:after:-bottom-2 hover:after:left-0 after:transition-all after:duration-300"
              >
                <NuxtLink :to="`/${locale}/${category?.seotag}`">
                  {{ category.category_name }}
                </NuxtLink>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="mt-2 col-span-6 grid grid-cols-6 gap-x-3 gap-y-2">
              <div
                v-for="category in allCategories"
                :key="category.category_id"
                class="relative pt-[90%] w-[90%]"
              >
                <div class="absolute inset-0">
                  <MenuChipsSliderItem
                    :category="category"
                    :showAllSubcategories="totalCategories <= 6"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  items: Array,
})

const { locale, t } = useI18n()

const scrolledRight = ref(false)
const scrolledRightEnd = ref(false)

const desktopMenu = ref()

const { isMobileOrTablet } = useDevice()

const { data } = await useFetch(`/api/public/frontend/menu/${locale.value}`)

const { response: menu } = data.value

const emit = defineEmits(['close', 'hoverMenu'])

const menuHovered = ref(false)
const sectionHovered = ref()

const hoverMenu = (section, value) => {
  menuHovered.value = value
  if (section != null) {
    sectionHovered.value = section
  }

  emit('hoverMenu', value)
}

const hoverCollapse = () => {
  menuHovered.value = !menuHovered.value
  emit('hoverMenu', menuHovered.value)
}

const scrollLeft = () => {
  scrolledRightEnd.value = false
  const scrollLeft = desktopMenu.value.scrollLeft
  const clientWidth = desktopMenu.value.clientWidth

  desktopMenu.value.scrollTo({
    left: scrollLeft - clientWidth,
    behavior: 'smooth',
  })

  if (scrollLeft < clientWidth) {
    scrolledRight.value = false
  }
}

const scrollRight = () => {
  scrolledRight.value = true
  const scrollLeft = desktopMenu.value.scrollLeft
  const clientWidth = desktopMenu.value.clientWidth

  desktopMenu.value.scrollTo({
    left: scrollLeft + clientWidth,
    behavior: 'smooth',
  })

  if (scrollLeft + clientWidth >= desktopMenu.value.scrollWidth - clientWidth) {
    scrolledRightEnd.value = true
  }
}

const categoriesWithChildren = computed(() => {
  return (
    sectionHovered.value?.children?.children.filter(
      (category) => category.children && category.children.length > 0
    ) || []
  )
})

const categoriesWithoutChildren = computed(() => {
  return (
    sectionHovered.value?.children?.children.filter(
      (category) => !category.children || category.children.length === 0
    ) || []
  )
})

const allCategories = computed(() => {
  return [...categoriesWithChildren.value, ...categoriesWithoutChildren.value]
})

const totalCategories = computed(() => {
  return allCategories.value.length
})
</script>
