<i18n>
  {
    "es": {
      "FIND_STORE": "Encontrar una tienda",
      "CART": "Carrito",
      "ACCESS": "Acceso",
      "BRANDS": "Marcas",
      "FAVOURITES": "Favoritos",
      "MY_ACCOUNT": "Mi cuenta",
      "CLOSE_SESSION": "Cerrar sesión"
    },
    "en": {
      "FIND_STORE": "Find a Store",
      "CART": "Cart",
      "ACCESS": "Access",
      "BRANDS": "Brands",
      "FAVOURITES": "Favourites",
      "MY_ACCOUNT": "My Account",
      "CLOSE_SESSION": "Close session"
    }
  }
</i18n>

<template>
  <div>
    <MenuMobileLinks
      v-for="section in items"
      :key="section?.section_id"
      :section="section"
    />
    <ClientOnly>
      <div
        class="max-w-xl fixed top-0 left-0 w-4/5 h-full z-[201] transition-all duration-300 border-t-8 border-[#3E5391]"
        :class="visibility ? 'translate-x-0' : '-translate-x-full'"
      >
        <div
          class="bg-white overflow-x-hidden h-full relative"
          :class="
            valueStack && valueStack.length > 0
              ? 'overflow-y-hidden'
              : 'overflow-y-scroll'
          "
        >
          <div
            class="flex items-center justify-between p-4 fixed top-0 left-0 right-0 z-50 bg-white shadow"
          >
            <button type="button" @click="close">
              <img src="@/assets/svg/icons/icons-homedelivery.svg" />
            </button>

            <NuxtLink
              :to="`/${locale}/tiendas`"
              class="flex items-center gap-1"
            >
              <img src="@/assets/svg/icons/icons-location.svg" />
              <span class="underline text-sm underline-offset-1 font-light">{{
                t('FIND_STORE')
              }}</span>
            </NuxtLink>

            <button type="button" @click="close">
              <img src="@/assets/svg/icons/icons-close.svg" />
            </button>
          </div>

          <div class="h-full mt-14">
            <div class="flex flex-col items-start w-full divide-y-1">
              <div class="w-full">
                <div class="flex items-center justify-between w-full px-3 py-6">
                  <NuxtLink
                    @click="close"
                    :to="`/${locale}/marcas`"
                    class="text-xs font-bold text-others-black"
                    >{{ t('BRANDS') }}</NuxtLink
                  >
                </div>
              </div>
              <MenuMobileItem
                v-for="section in items"
                :key="section?.section_id"
                :section="section"
                @openSubmenu="openSubmenu($event)"
              />
              <MenuMobileItemCourtain
                :content="lastValue"
                @openSubmenu="openSubmenu($event)"
                @close="close"
                @beforeValue="beforeValue"
              />
              <MenuMobileItemCourtain
                :content="actualValue"
                @openSubmenu="openSubmenu($event)"
                @close="close"
                @beforeValue="beforeValueActual"
              />
            </div>
            <div class="flex flex-col mt-11 bg-others-grey">
              <button
                type="button"
                @click="closeAndOpenCart"
                class="flex items-center gap-2 w-full px-3 py-6"
              >
                <img src="@/assets/svg/shoppingbag.svg" class="w-4 h-4" />
                <span class="text-sm font-normal text-others-black">{{
                  t('CART')
                }}</span>
              </button>
              <NuxtLink
                :to="`/${locale}/account/favourites`"
                class="flex items-center gap-2 w-full px-3 py-6"
              >
                <img src="@/assets/svg/favourite.svg" class="w-4 h-4" />
                <span class="text-sm font-normal text-others-black">{{
                  t('FAVOURITES')
                }}</span>
              </NuxtLink>
              <div
                v-if="isLogged"
                class="flex items-center justify-between w-full px-3 py-6"
              >
                <NuxtLink :to="`/${locale}/account`">
                  <div class="flex items-center gap-2">
                    <img src="@/assets/svg/account.svg" class="w-4 h-4" />
                    <span class="text-sm font-normal text-others-black">{{
                      t('MY_ACCOUNT')
                    }}</span>
                  </div>
                </NuxtLink>
                <button
                  @click="handleLogout"
                  class="underline text-xs font-normal"
                >
                  {{ t('CLOSE_SESSION') }}
                </button>
              </div>
              <div
                v-else
                class="flex items-center justify-between w-full px-3 py-6"
              >
                <NuxtLink
                  :to="`/${locale}/account`"
                  class="flex items-center gap-2"
                >
                  <img src="@/assets/svg/account.svg" class="w-4 h-4" />
                  <span class="text-sm font-normal text-others-black">{{
                    t('MY_ACCOUNT')
                  }}</span>
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ClientOnly>

    <div
      :class="visibility ? 'translate-x-0' : '-translate-x-full'"
      class="fixed top-0 left-0 w-full h-full bg-black opacity-40 z-200"
      @click="close"
    ></div>
  </div>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
  items: Array,
})

const router = useRouter()
const { t, locale } = useI18n({
  useScope: 'local',
})

const { addMessage } = useSnackBar()

const valueStack = ref([])
const lastValue = computed(() => {
  if (valueStack.value === null) return null
  return valueStack.value[valueStack.value.length - 1]
})
const actualValue = ref()

const isLogged = ref(false)

onMounted(async () => {
  const { isLoggedIn } = useAuthUser()
  if (import.meta.client) {
    isLogged.value = await isLoggedIn()
  }
})

const openSubmenu = (content) => {
  if (!actualValue.value) {
    actualValue.value = content
    setTimeout(() => {
      valueStack.value.push(content)
    }, 200)
    return
  }
  actualValue.value = null
  setTimeout(() => {
    actualValue.value = content
  }, 100)
  setTimeout(() => {
    valueStack.value.push(content)
  }, 200)
}

const beforeValue = () => {
  const copyOfStack = JSON.stringify(valueStack.value)
  valueStack.value = null
  setTimeout(() => {
    const parsedCopy = JSON.parse(copyOfStack)
    parsedCopy.pop()
    valueStack.value = parsedCopy
  }, 100)
}

const beforeValueActual = () => {
  const copyOfStack = JSON.stringify(valueStack.value)
  valueStack.value = null
  setTimeout(() => {
    valueStack.value = JSON.parse(copyOfStack)
    actualValue.value = null
    valueStack.value.pop()
  }, 100)
}

const emit = defineEmits(['close'])

const close = () => {
  valueStack.value = []
  actualValue.value = null
  emit('close')
}

const closeAndOpenCart = () => {
  close()
  const { show } = useCart()
  show()
}

const handleLogout = async () => {
  try {
    const { logout } = useAuthUser()
    await logout()
    isLogged.value = false
    router.push(`/${locale.value}/login`)
  } catch (error) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('UNKNOWN_ERROR'),
    })
  }
}
</script>
