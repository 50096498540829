<i18n>
  {
    "es": {
      "SEE_ALL": "Ver todo"
    },
    "en": {
      "SEE_ALL": "See all"
    }
  }
</i18n>

<template>
  <div class="flex flex-col items-start w-full gap-1.5">
    <NuxtLink
      :to="`/${locale}/${category?.seotag}`"
      class="mb-1 text-sm tracking-widest leading-5 font-semibold relative uppercase cursor-pointer after:w-0 hover:after:w-full hover:after:h-0.5 hover:after:bg-sanremo-primaryblue hover:after:content-normal hover:after:absolute hover:after:-bottom-1 hover:after:left-0 after:transition-all after:duration-300"
      >{{ category.category_name }}</NuxtLink
    >

    <div class="h-full flex flex-col justify-between items-start w-full">
      <NuxtLink
        v-for="child in showAllSubcategories
          ? category.children
          : category.children.slice(0, 5)"
        :key="child.category_id"
        :to="`/${locale}/${child?.seotag}`"
        class="font-normal relative text-xs tracking-widest leading-5 cursor-pointer truncate max-w-full overflow-hidden whitespace-nowrap after:w-0 hover:after:w-full hover:after:h-0.5 hover:after:bg-sanremo-primaryblue hover:after:content-normal hover:after:absolute hover:after:-bottom-0 hover:after:left-0 after:transition-all after:duration-300"
        >{{ child.category_name }}</NuxtLink
      >

      <NuxtLink
        v-if="category.children.length > 5 && !showAllSubcategories"
        :to="`/${locale}/${category?.seotag}`"
        class="mt-2 text-sanremo-primaryblue text-xs tracking-widest cursor-pointer font-medium relative after:w-0 hover:after:w-full hover:after:h-0.5 hover:after:bg-sanremo-primaryblue hover:after:content-normal hover:after:absolute hover:after:-bottom-0 hover:after:left-0 after:transition-all after:duration-300"
        >{{ t('SEE_ALL') }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  category: Object,
  showAllSubcategories: Boolean,
})

const { locale, t } = useI18n()
</script>
